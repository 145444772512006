<template>
  <div class="row">
    <div class="col-12">
      <ibox
        :title="
          `${$route.params.shop_name} ${$route.params.shop_lang} ${$t(
            'products'
          )}`
        "
      >
        <template slot="content" slot-scope="{ setLoading }">
          <DataTable
            :url="
              `/${$route.params.shop_name}/${$route.params.shop_lang}/products`
            "
            @loading="setLoading"
          />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox";
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "ProductList",
  components: {
    Ibox,
    DataTable
  }
};
</script>
